<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab>Кнопки</v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div v-show="isExists" class="form-subheader">Сведения</div>
          <b-form-group
            id="input-group-0"
            v-show="isExists"
            label="OID"
            label-for="input-0"
          >
            <b-form-input
              id="input-0"
              :value="form.OID"
              placeholder="OID"
              disabled
            ></b-form-input>
          </b-form-group>

          <!--<b-form-group id="input-group-2" label="Имя" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="form.name"
              placeholder="Имя"
              disabled
            ></b-form-input>
          </b-form-group>-->

          <div class="form-subheader">Редактируемые</div>
          <b-form>
            <b-form-group id="input-group-1" label="Код" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="form.Code"
                placeholder="Код"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Название напоминалки"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.Name"
                placeholder="Название напоминалки"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Заголовок"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="form.Title"
                placeholder="Заголовок"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-4"
              label="Текст описания"
              label-for="input-4"
            >
              <b-form-input
                id="input-4"
                v-model="form.Subtitle"
                placeholder="Текст описания"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-5"
              label="Ссылка на изображение"
              label-for="input-5"
            >
              <upload-file id="inpit-5" v-model="form.Media" />
            </b-form-group>

            <b-form-group id="input-group-6" label="Cкрипт" label-for="input-6">
              <b-form-select
                v-model.trim="form.ScriptOID"
                :options="scripts"
                id="input-6"
              />
            </b-form-group>

            <b-form-group
              id="input-group-7"
              label="Действует ОТ"
              label-for="input-7"
            >
              <b-form-input
                id="input-7"
                v-model="form.DayFrom"
                placeholder="Действует ОТ"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-8"
              label="Действует ДО"
              label-for="input-8"
            >
              <b-form-input
                id="input-8"
                v-model="form.DayTo"
                placeholder="Действует ДО"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-9">
              <switch-checkbox v-model="form.IsActive" label="Активна" />
            </b-form-group>

            <div class="form-subheader">Настройки push-уведомлений</div>

            <b-form-group
              id="input-group-11"
              label="Заголовок"
              label-for="input-11"
            >
              <b-form-input
                id="input-11"
                v-model="form.PushTitle"
                placeholder="Заголовок"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-12"
              label="Текст"
              label-for="input-12"
            >
              <b-form-input
                id="input-12"
                v-model="form.PushSubtitle"
                placeholder="Текст"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-13"
              label="Ссылка на изображение"
              label-for="input-13"
            >
              <b-form-input
                id="input-8"
                v-model="form.PushImageUrl"
                placeholder="Ссылка на изображение"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-14">
              <switch-checkbox
                v-model="form.IsNeedSendPush"
                label="Отправляем пуш?"
              />
            </b-form-group>

            <b-form-group
              id="input-group-15"
              label="Время отправки"
              label-for="input-15"
            >
              <b-form-input
                id="input-15"
                type="time"
                v-model="form.PushSendTime"
                placeholder="Время отправки"
              ></b-form-input>
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>

      <v-tab-item>
        <div class="row">
          <div class="col-md-12">
            <custom-header title="Кнопки">
              <create-button :parent-id="form.OID" @saved="updateButtons" />
            </custom-header>

            <list-buttons
              :parent-id="form.OID"
              :items="form.Button"
              url-name="notifications-buttons-detail"
              @updated="updateButtons"
            />
          </div>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import SwitchCheckbox from '@/view/content/SwitchCheckbox';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import UploadFile from '@/view/content/widgets/FileDropSingleMS';
import {
  SAVE_ITEM_NOTIFICATION_TEMPLATES,
  GET_DICT_NOTIFICATION_SCRIPTS,
} from '@/core/services/store/notifications.module';

import ListButtons from '@/view/content/lists/notifications/buttons/ListButtons';
import CreateButton from '@/view/content/widgets/notifications/buttons/CreateButton.vue';

export default {
  components: {
    SwitchCheckbox,
    UploadFile,
    ListButtons,
    CreateButton,
  },

  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
      scripts: [],
    };
  },

  computed: {
    isExists() {
      return this.form?.OID;
    },

    typeOptions() {
      return this.$store.getters.reminderTypesOptions;
    },
  },

  validations: {
    form: {
      Code: {
        required,
      },
      Name: {
        required,
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },

    value: {
      handler(v) {
        this.form = v;
      },
      deep: true,
    },
  },

  async beforeMount() {
    const scripts = await this.$store.dispatch(GET_DICT_NOTIFICATION_SCRIPTS);

    if (this.form.Button === null) {
      this.form.Button = [];
    }

    this.scripts = scripts.map((x) => {
      return { value: x.OID, text: x.Name };
    });
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    selectButton(e) {
      if (e.selected) {
        const index = this.$refs.buttonSelect.options.findIndex(
          (x) => parseInt(x.id) === parseInt(e.id)
        );

        this.$refs.buttonSelect.options.splice(
          this.$refs.buttonSelect.options.length - 1,
          0,
          this.$refs.buttonSelect.options.splice(index, 1)[0]
        );
      }
    },

    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      } else {
        const result = await this.$store.dispatch(
          SAVE_ITEM_NOTIFICATION_TEMPLATES,
          this.form
        );

        if (result.error === null) {
          Swal.fire({
            title: '',
            text: this.isExists ? 'Сохранено!' : 'Создано!',
            icon: 'success',
            heightAuto: false,
          });
          return true;
        }
      }
      return false;
    },

    updateButtons() {
      this.$emit('saved');
    },
  },
};
</script>

<style lang="scss" scoped></style>
