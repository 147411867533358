<template>
  <div class="create-block-buttons block-element">
    <b-button variant="success" @click="openModal"> Создать </b-button>
    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Создание кнопки</h5>
      </template>

      <notificaions-buttons-form
        v-model="createItem"
        ref="notificationsButtonsCreateForm"
      />

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="saveForm">
          Создать
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NotificaionsButtonsForm from '@/view/content/forms/notifications/buttons/ButtonsForm';

export default {
  components: {
    NotificaionsButtonsForm,
  },

  props: {
    parentId: {
      type: [Number, String],
      default: () => null,
    },
  },

  data() {
    return {
      modalShow: false,
      createItem: {
        Code: '',
        Name: '',
        IsActive: true,
        TemplateOID: this.parentId,
        BGHEXColor: null,
        TextHEXColor: null,
        ActionPath: null,
      },
    };
  },

  methods: {
    openModal() {
      this.clearForm();
      this.modalShow = true;
    },

    async saveForm() {
      const result = await this.$refs?.notificationsButtonsCreateForm?.submit();

      if (result) {
        this.modalShow = false;
        this.$emit('saved');
      }
    },

    clearForm() {
      this.createItem = {
        Code: '',
        Name: '',
        IsActive: true,
        IsPositive: true,
        TemplateOID: this.parentId,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
