<template>
  <span
    :style="{ color: textColor, 'background-color': bgColor }"
    class="label label-lg label-inline custom-badge"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: () => '',
    },

    bgColor: {
      type: String,
      default: '#ebebeb',
    },

    textColor: {
      type: String,
      default: ' #7B7B7B',
    },
  },
};
</script>

<style lang="scss" scoped>
.yes-no-badge {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
