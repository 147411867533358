<template>
  <div class="create-block-buttons block-element">
    <b-button variant="success" @click="openModal"> Создать </b-button>
    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Создание шаблона</h5>
      </template>

      <notificaions-templates-form
        v-model="createItem"
        ref="notificationsTemplatesCreateForm"
      />

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="saveForm">
          Создать
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NotificaionsTemplatesForm from '@/view/content/forms/notifications/templates/TemplatesForm';

export default {
  components: {
    NotificaionsTemplatesForm,
  },

  data() {
    return {
      modalShow: false,
      createItem: {
        Code: '',
        Title: '',
        Subtitle: '',
        ImageUrl: '',
        ScriptOID: null,
        Name: '',
        Button: [],
        IsActive: true,
        DayFrom: 0,
        DayTo: 0,
      },
    };
  },

  methods: {
    openModal() {
      this.clearForm();
      this.modalShow = true;
    },

    async saveForm() {
      const result =
        await this.$refs?.notificationsTemplatesCreateForm?.submit();

      if (result) {
        this.modalShow = false;
        this.$emit('saved');
      }
    },

    clearForm() {
      this.createItem = {
        Code: '',
        Title: '',
        Subtitle: '',
        ImageUrl: '',
        ScriptOID: null,
        Name: '',
        Button: [],
        IsActive: true,
        DayFrom: 0,
        DayTo: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
