<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <v-expansion-panels class="mb-4">
            <v-expansion-panel>
              <v-expansion-panel-header> Фильтры </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <filter-templates />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <custom-header title="Шаблоны">
            <create-button @saved="loadItems" />
          </custom-header>

          <list-templates
            :items="filterItems"
            :filtered="filtered"
            url-name="notifications-templates-detail"
            @updated="loadItems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SET_BREADCRUMB,
  SET_BREADCRUMB_SHOW_BACK,
} from '@/core/services/store/breadcrumbs.module';

import { GET_NOTIFICATION_TEMPLATES } from '@/core/services/store/notifications.module';
import FilterTemplates from '@/view/content/filters/notifications/templates/FilterTemplates';
import FilteredRouteItemsMixin from '@/view/content/filters/notifications/FilteredRouteItemsMixin';
import ListTemplates from '@/view/content/lists/notifications/templates/ListTemplates';
import CreateButton from '../../../content/widgets/notifications/templates/CreateButton.vue';

export default {
  mixins: [FilteredRouteItemsMixin],

  components: { FilterTemplates, ListTemplates, CreateButton },

  computed: {
    ...mapState({
      items: (state) => state.notifications.templates,
    }),
  },

  async beforeMount() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Напоминалки' },
      { title: 'Шаблоны' },
    ]);
    this.$store.commit(SET_BREADCRUMB_SHOW_BACK, false);
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      await this.$store.dispatch(GET_NOTIFICATION_TEMPLATES, this.$route.query);
    },
  },
};
</script>

<style lang="scss" scoped></style>
