<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-code"
              label="Код"
              label-for="input-code"
            >
              <b-form-input
                id="input-code"
                v-model.trim="f.Code"
                placeholder="Код"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group
              id="input-group-name"
              label="Название напоминалки"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model.trim="f.Name"
                placeholder="Название напоминалки"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group
              id="input-group-title"
              label="Заголовок"
              label-for="input-title"
            >
              <b-form-input
                id="input-title"
                v-model.trim="f.Title"
                placeholder="Заголовок"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-subtitle"
              label="Текст описания"
              label-for="input-subtitle"
            >
              <b-form-input
                id="input-subtitle"
                v-model.trim="f.Subtitle"
                placeholder="Текст описания"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group
              id="input-group-scriptname"
              label="Название скрипта"
              label-for="input-scriptname"
            >
              <b-form-input
                id="input-scriptname"
                v-model.trim="f.ScriptName"
                placeholder="Название скрипта"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-route"
              label="Активен"
              label-for="input-route"
            >
              <switch-checkbox v-model="f.IsActive" label="Активен" />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@/view/content/filters/FiltersMixin';
import SwitchCheckbox from '@/view/content/SwitchCheckbox';
export default {
  components: { SwitchCheckbox },
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        Code: this.$route.query?.Code,
        Name: this.$route.query?.Name,
        Title: this.$route.query?.Title,
        Subtitle: this.$route.query?.Subtitle,
        ScriptName: this.$route.query?.ScriptName,
        IsActive: this.$route.query?.IsActive,
        ImageUrl: this.$route.query?.ImageUrl,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;
  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
