import { render, staticRenderFns } from "./CreateButton.vue?vue&type=template&id=74d7b890&scoped=true"
import script from "./CreateButton.vue?vue&type=script&lang=js"
export * from "./CreateButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74d7b890",
  null
  
)

export default component.exports