<template>
  <div class="auto-dealer-table">
    <v-data-table
      v-sortable-data-table
      v-if="items !== null"
      :headers="headers"
      :items="localItems"
      item-key="ID"
      :item-class="itemClass"
      class="elevation-1 row-links"
      hide-default-footer
      disable-pagination
      @sorted="saveOrder"
    >
      <template #item.IsActive="{ item }">
        <yes-no-badge :active="item.IsActive" />
      </template>

      <template #item.ID="{ item }">
        <a
          href="#"
          title="Редактировать"
          event=""
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          @click.prevent="clickRow(item)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </a>
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal
      v-model="modalShow"
      scrollable
      size="xl"
      @ok="saveForm"
      @hide="editItem = null"
    >
      <template #modal-title>
        <h5>Редактирование {{ editedName }}</h5>
      </template>

      <buttons-form
        v-model="editItem"
        ref="notificationsButtonForm"
        @saved="saved"
      />

      <template #modal-footer="{ cancel }">
        <b-button
          :disabled="buttonLoading"
          size="sm"
          variant="success"
          @click="saveForm"
        >
          Сохранить
        </b-button>
        <b-button
          :disabled="buttonLoading"
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import YesNoBadge from '@/view/content/YesNoBadge';
import ButtonsForm from '@/view/content/forms/notifications/buttons/ButtonsForm.vue';
import { SORT_NOTIFICATION_BUTTONS } from '@/core/services/store/notifications.module';
import Sortable from 'sortablejs';

export default {
  components: { YesNoBadge, ButtonsForm },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          filter: '.filtered',
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    parentId: {
      type: [String, Number],
      default: () => null,
    },

    urlName: {
      type: String,
      deafult: () => null,
    },

    filtered: {
      type: Boolean,
      deafult: () => false,
    },
  },

  data() {
    return {
      modalShow: false,
      editItem: null,
      localItems: this.items,
      lastEditId: null,
      buttonLoading: false,
      sortAllow: false,
      headers: [
        { text: 'Код', value: 'Code', sortable: false },
        { text: 'Текст на кнопке', value: 'Name', sortable: false },
        {
          text: ' Активна',
          value: 'IsActive',
          sortable: false,
        },
        { text: 'Действия', sortable: false, value: 'ID' },
      ],
    };
  },

  computed: {
    itemsSort() {
      if (Array.isArray(this.localItems)) {
        return this.localItems.map((x, i) => {
          return { ID: x.ID, SortOrder: i + 1 };
        });
      }
      return [];
    },

    editedName() {
      return this.editItem?.Name;
    },
  },

  mounted() {
    if (this.localItems === null) {
      this.localItems = [];
    }

    setTimeout(this.setAllowSort, 3000);
  },

  watch: {
    items(v) {
      if (v === null) {
        this.localItems = [];
      } else {
        this.localItems = v;
      }
    },

    itemsSort: {
      handler(v) {
        if (v?.length !== 0 && this.sortAllow) {
          this.$store.dispatch(SORT_NOTIFICATION_BUTTONS, v);
        }
      },

      deep: true,
    },
  },

  methods: {
    setAllowSort() {
      this.sortAllow = true;
    },

    saved() {
      this.$emit('updated');
    },

    async clickRow(item) {
      this.lastEditId = item.ID;
      this.editItem = item;
      this.editItem.TemplateOID = this.parentId;
      this.modalShow = true;
      return false;
    },

    async saveForm() {
      this.buttonLoading = true;
      const result = await this.$refs?.notificationsButtonForm?.submit();
      this.buttonLoading = false;

      if (result) {
        this.modalShow = false;
        this.$emit('updated');
      }
    },

    saveOrder(event) {
      const movedItem = this.localItems.splice(event.oldIndex, 1)[0];
      this.localItems.splice(event.newIndex, 0, movedItem);
    },

    itemClass() {
      return this.filtered ? 'filtered' : '';
    },
  },
};
</script>

<style lang="scss"></style>
